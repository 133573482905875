.orange {
    -webkit-text-fill-color: transparent;
    background: linear-gradient(90deg,#ff5e14,#ffc0a4);
    background-clip: initial;
    -webkit-background-clip: text;
}

.centered {
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;
}

.tier {
    border: 1px solid #575757;
    border-radius: 15px;
    padding: 15px;
    text-align: left;
}

.selectedTier {
    border: 1px solid #fb5c0f;
}

.small {
    font-size: 15px;
}

.btnDisabled {
    background-color: #373737!important;
    background-image: none!important;
    cursor:not-allowed!important;
    border: 1px solid #5a5a5a!important;
    pointer-events: all!important;
}

.btnDisabled {
    cursor: not-allowed!important;
}

.emergencyBtn {
    margin-top: 20px!important;
    background-image: linear-gradient(90deg,#9e0808,#dd0d0d)!important;
    border-radius: 15px!important;
    color: #fff!important;
}

.emergencyBtn:hover {
    background-image: linear-gradient(90deg,#720404,#9e0b0b)!important;
    border-radius: 15px!important;
    color: #fff!important;
}

.infoBtn {
    margin-top: 20px!important;
    background-image: linear-gradient(90deg,#858585,#525252)!important;
    border-radius: 15px!important;
    color: #fff!important;
    margin-right: 5px!important;
}

.infoBtn:hover {
    background-image: linear-gradient(90deg,#636363,#2e2e2e)!important;
    border-radius: 15px!important;
    color: #fff!important;
}

.claimBtn {
    background-image: linear-gradient(90deg,#1d7e1d,#2ab92a)!important;
    border-radius: 15px!important;
    color: #fff!important;
}

.claimBtn:hover {
    background-image: linear-gradient(90deg,#196119,#229922)!important;
    border-radius: 15px!important;
    color: #fff!important;
}

.stakingContainer h6 {
    font-size: 15px;
}

@media screen and (max-width: 540px) {
    .stakingContainer h6,
    .stakingContainer h6 span {
        font-size: 13px;
    }

    .stakingContainer h4 {
        font-size: 20px;
        padding-bottom: 10px;
    }

    .orangeBtn {
        font-size: 11px!important;
    }
}

@media screen and (max-width: 1385px) {
    .orangeBtn {
        font-size: 10px!important;
    }
}

@media screen and (max-width: 690px) {
    .orangeBtn {
        font-size: 13px!important;
    }
}

.MuiDialog-paper {
    background: #1c1c1c!important;
    border-radius: 15px;
}

.MuiDialogActions-root {
    flex: 0 0 auto;
    display: flex;
    padding: 20px;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: stretch;
}

.noMargin {
    margin: 0px;
}