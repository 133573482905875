@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200&display=swap');

.highlight {
  color: #7f78d2;
}

.legend {
    -webkit-text-fill-color: transparent;
    background: linear-gradient(90deg,#ff5e14,#ffc0a4);
    background-clip: initial;
    -webkit-background-clip: text;
    font-size: 16px;
}

.legend2 {
    color: #959595!important;
}

.countdown-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  margin: 10px 0;
}

.countdown-box {
    border: 1px solid #959595;
    font-size: 12px;
    font-weight: 700;
    border-radius: 15px;
    width: 50px;
    height: 50px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 5px;
}