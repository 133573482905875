@font-face {
  font-family: archivo;
  src: url('./Fonts/Archivo/Archivo-Regular.ttf') format("truetype");
}

html, body, main {
  width: 100%;
  margin: 0;
}

body {
  background: #000;
  color: #555555;
  font-family: archivo;
  font-size: 15px;
  font-weight: normal;
  line-height: 1em;
}

.App {
  display: flex;
  width: 100%;
  margin: 0;
  background: url("/public/star-bg.svg");
  background-size: contain;
  height: 100%;
  width: 100%;
}

.outer {
  padding: 10px;
}

.wallet-container {
  width: 80%;
  margin: auto;
  border: 2px #ffffff solid;
  padding: 25px;
  border-radius: 25px;
  margin-top: 50px;
  background-color: #000000;
  text-align: center;
  color: #fff;
}

.input {
  width: 1;
  color: #777;
  padding: 8;
  margin: 10;
  width: 200;
}

.myform {
  padding: 20px;
}

.myform input[type="text"],
.myform textarea {
  padding: 8px;
  width: 100%;
}

.column {
  padding: 20px;
  float: left;
  width: 50%;
}

.column_aut {
  padding: 20px;
  float: left;
  width: 33%;
}

.Token_message {
  display: inline;
}

.swap {
  padding: 10px;
}

.swap_button {
  float: right;
}

.markdown {
  width: 80%;
  margin: auto;
}

.logo-text{
  display: flex;
  align-items: center;
  padding: 15px;
  padding-top: 20px;
  justify-content: space-between;
}

.btn-connect button {
  height: 45px;
  color: white;
  border-radius: 4px;
  padding: 7px 25px 8px 25px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 14px;
  display: inline-block;
  border: 2px solid #ffffff;
  margin-right: 5px;
  border-radius: 50px;
  font-family: archivo;
}

.btn-connect button:hover {
  border-color: #36c;
  color: #ffffff;
}

.logo{
  display: flex;
  align-items: center;
}

.logo-text > .logo > span {
  margin-left: 15px;
}

.container {
  max-width: 1320px;
  margin: 0 auto;
  padding: 0 30px;
  text-align: center;
}

@media (max-width: 768px) {
  .container {
      max-width: 667px;
      margin: 0 auto;
      padding: 0 30px;
  }
}
.header h1 {
  margin: 0px;
  padding: 0px;
}

.hidden {
  display:none!important;
}

.connect-container {
  display: flex;
}

.errorMsg {
  color: #ac5858;
  margin-top: 15px;
  text-align: center;
}

.MuiFormControlLabel-label p {
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 16px!important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-bar {
  height: 50px;
}

@media only screen and (max-width: 825px)  {
  .nav-bar {
    display: none;
  }
  .btn-connect button {
    font-size: 10px;
    padding: 0px 15px 0px 15px;
  }
}

@media only screen and (max-width: 580px)  {
  .MuiTabs-flexContainer {
    display: flex!important;
    flex-wrap: wrap!important;
    align-content: stretch!important;
    justify-content: space-evenly!important;
  }
}

.MuiContainer-root {
  margin-top: 50px!important;
  display: block!important;
  justify-content: center;
  align-items: center;
  width: unset!important;
}

.MuiPaper-root {
  background-color: transparent!important;
  color: #fff!important;
}

.MuiTypography-h4 {
  color: #fff!important;
  font-family: archivo!important;
}

.MuiTableCell-body,
.MuiTypography-colorTextSecondary,
.MuiFormLabel-root,
.MuiInputBase-root,
.MuiOutlinedInput-root {
  color: #fff!important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: rgb(255 255 255 / 45%)!important;
}

.primary-button {
  background-color: #000!important;
  color: #fff!important;
  border: 1px solid #fff!important;
  border-radius: 50px!important;
}

.primary-button2 {
  background-color: #000!important;
  color: #fff!important;
  border-radius: 50px!important;
}

.transparent {
  background-color: transparent!important;
}

.error-button {
  background-color: #000!important;
  color: #ff2525!important;
  border-radius: 50px!important;
}

.primary-button:hover {
  border-color: #36c!important;
}

.primary-button2:hover {
  color: #36c!important;
}

.error-button:hover {
  color: rgb(182, 16, 16)!important; 
}

.MuiBackdrop-root {
  background-color: rgb(0 0 0 / 79%)!important;
}

.MuiDialog-paper {
  border-radius: 25px;
}

.MuiCheckbox-root {
  color: rgb(255 255 255 / 60%)!important;
}

.loadingText {
  color: #fff;
}

.MuiTableCell-root {
  border-bottom: 1px solid rgb(54 54 54)!important;
  padding-top: 15px!important;
  padding-bottom: 15px!important;
}

.MuiPaper-root h6 {
  font-size: 18px;
  font-family: archivo;
  color: #959595;
}

main {
  width: 100%;
}

.snackbar {
  z-index: 99999999999!important;
}

.MuiCollapse-root {
  width: 100%!important;
}

.MuiCollapse-wrapperInner {
  width: fit-content!important;
}

.cancelButton {
  background-color: #ff2525!important;
  color: #fff!important;
  border:none;
}

.cancelButton:hover {
  background-color: #c91f1f!important;
}

.MuiDialogTitle-root {
  border-bottom: 1px solid #404040;
  margin-bottom: 15px!important;
}

.MuiDialogActions-root {
  border-top: 1px solid #404040;
  padding-top: 15px!important;
  padding-bottom: 15px!important;
}

.noBorder {
  border: none!important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #232329; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #3f3f3f; 
  border-radius: 15px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.tw-connected-wallet__address {
  color: #000!important;
}