@font-face {
    font-family: archivo;
    src: url('../../Fonts/Archivo/Archivo-Regular.ttf') format("truetype");
}

.ps-sidebar-root {
    border-color: #252525!important;
    z-index: 9999999!important;
}

.logo-area {
    padding: 15px;
    text-align: center;
    padding-top: 25px;
    margin-bottom: 25px;
}

.ps-menu-button:hover {
    background-color: transparent!important;
    color: rgb(136, 136, 136)!important;
}

.ps-active > span {
    -webkit-text-fill-color: transparent;
    background: linear-gradient(90deg,#ff5e14,#ffc0a4);
    background-clip: initial;
    -webkit-background-clip: text;
}

.menu-item-link {
    text-decoration: none;
    color: #fff;
    font-weight: 100;
    font-family: archivo;
}

.menu-item h2 {
    font-size: 18px;
}

@media screen and (max-width: 1040px) {
    .Header {
        margin-bottom: 90px;
    }
}


@media screen and (max-width: 540px) {
    .ps-sidebar-root {
        border: none!important;
    }
}

@media screen and (max-width: 768px) {
    .ps-sidebar-container {
        background-color: #1e1e1e!important; 
    }
}

.ps-sidebar-container {
    background-color: #adadad12;
}

.ps-menu-label span div {
    width: 20px;
    display: inline-grid;
    margin-left: 15px;
    float: right;
}