@font-face {
    font-family: archivo;
    src: url('../../Fonts/Archivo/Archivo-Regular.ttf') format("truetype");
}

body {
    font-family: archivo;
}

.expand-menu .MuiSvgIcon-root {
    color: #fff;
    font-size: 33px;
    cursor: pointer;
}

.expand-menu  {
    padding-left: 5px;
}

.orangeBtn {
    background-image: linear-gradient(90deg,#ff5e14,#f88f62)!important;
    border-radius: 15px!important;
    color: #fff!important;
}

.orangeBtn:hover {
    background-image: linear-gradient(90deg,#cf5116,#db8058)!important;
}

@media screen and (max-width: 1040px) {
    .Header {
        margin-bottom: 90px;
    }
}

.ps-menu-icon svg {
    color: #fb5c0f!important;
}

